<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="table" :data="tableData" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}" row-key="id"
							:tree-props="{ children: 'child'}" @row-click="expandChange" style="margin-bottom: 20px;">
							<el-table-column prop="name" label="架构名称" show-overflow-tooltip>
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="460">
								<template slot-scope="scope">
									<el-button type="text" @click="insertSub(scope.row)">
										添加下级
									</el-button>
									<el-button type="text" @click="rename(scope.row)">
										重命名
									</el-button>
									<el-button type="text" @click="remove(scope.row)">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

			</div>
		</div>

		<insertSub :isShow.sync="showInsertSubDialog" :dialogObj="insertSubObj"></insertSub>

		<rename :isShow.sync="showRenameDialog" :dialogObj="renameObj"></rename>

	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import insertSub from "./insertSub.vue"
	import rename from "./rename.vue"
	export default {
		components: {
			smCard,
			insertSub,
			rename
		},
		data() {
			return {
				tableHeight: 0,
				tableData: [],
				searchParm: {
					size: 10,
					current: 1
				},
				showInsertSubDialog: false,
				showRenameDialog: false,
				insertSubObj: {},
				renameObj: {},
			}
		},
		methods: {
			//点击某一行的回调事件
			expandChange(row, index, e) {
				if (index.label == '架构名称') {
					this.$refs.table.toggleRowExpansion(row)
				}
			},
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 10,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/user-api/plat/dept/tree", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开添加下级弹窗
			insertSub(row) {
				this.insertSubObj = {};
				this.insertSubObj["id"] = row.id;
				this.showInsertSubDialog = true;
			},
			//打开重命名弹窗
			rename(row) {
				this.renameObj = {};
				this.renameObj["id"] = row.id;
				this.showRenameDialog = true;
			},
			remove(row) {
				this.$confirm("是否确认删除？", "删除部门", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/user-api/plat/dept/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
