<template>
	<el-card :class="whiteHeader?'':'box-card'" shadow="hover" style="margin: 10px 0;"
		:body-style="noPadding?{'padding':'0'}:''">
		<slot slot="header" name="header" v-if="isCustomHeader"></slot>
		<div slot="header" class="clearfix" style="position: relative;" v-else-if="!noHeader">
			<i :class="icon"></i>
			<span>{{title}}</span>
			<div style="
    display: inline-block;
    position: absolute;
    right: 0;
    top: -6px;">
				<slot name="header-right"></slot>
			</div>
		</div>
		<slot name="body"></slot>
	</el-card>
</template>
<script>
	export default {
		components: {},
		data() {
			return {}
		},
		props: {
			title: {
				type: String,
				defalut() {
					return "";
				}
			},
			icon: {
				type: String,
				defalut() {
					return "";
				}
			},
			noHeader: {
				type: Boolean,
				defalut() {
					return false;
				}
			},
			noPadding: {
				type: Boolean,
				default () {
					return false;
				}
			},
			whiteHeader: {
				type: Boolean,
				default () {
					return false;
				}
			},
			isCustomHeader: {
				type: Boolean,
				default () {
					return false;
				}
			}
		},
		methods: {},
	};
</script>
<style lang='scss' scoped>
	.box-card {
		::v-deep .el-card__header {
			background-color: #f3f3f3;
		}

	}

	.el-card {
		overflow: visible;
	}
</style>
