<template>
	<el-dialog class="dialog" title="重命名" :visible.sync="visiable" width="30%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<el-form-item label="名称：" style="flex: 1;">
					<el-input v-model="name"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				formData: {},
				name: "",
				id: ""
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.name = "";
				this.get();
			},
			//提交表单
			submit() {
				this.loading = true;
				this.$post("/user-api/plat/dept/rename", {
					id: this.id,
					name: this.name
				}).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.$message.success(res.msg);
						this.$parent.list();
						this.close();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			get() {
				this.$get("/user-api/plat/dept/get", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						this.name = res.data.name;
						this.id = res.data.id;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
