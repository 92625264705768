export const dialogMixin = {
	data() {
		return {
			visiable: false,
		}
	},
	props: {
		isShow: {
			type: Boolean,
			default () {
				return false;
			}
		},
		dialogObj: {
			type: Object,
			default () {
				return {};
			}
		}
	},
	watch: {
		isShow(val) {
			if (val) {
				this.visiable = true;
				this.open();
			}
		},
		visiable(val) {
			this.$emit('update:isShow', val);
		}
	},
	methods: {
	}
}
